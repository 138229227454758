@use './fonts.scss';
@use './themes.scss';

/* Skeleton */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
}

#root {
  width: 100%;
  height: 100%;
}

/* Hover Effects */
.hover-darken:hover {
  filter: brightness(80%);
}
.hover-purple:hover {
  color: #b044f9 !important;
}
.vailable_antd_modal {
  overflow: hidden;
  border-radius: 10px;
  padding: 0;
}
.vailable_antd_modal_dark {
  overflow: hidden;
  border-radius: 10px;
  padding: 0;
}
.vailable_antd_modal_dark .ant-modal-content {
  background: #282b30;
}

/* Antd Styles */
@import '~antd/dist/antd.css';
.ant-switch-checked {
  background-color: #4c4e52;
}
.ant-switch-checked:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(174, 0, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(174, 0, 255, 0.2);
}
.ant-btn-primary {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}
.ant-btn-primary:hover {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #707070 !important;
}
.ant-btn {
  border-radius: 20px;
  background: var(--bg-color);
  color: var(--text-color);
}
.ant-btn:hover {
  border-color: var(--primary-color);
  background: var(--bg-color);
  color: var(--primary-color);
}
.ant-modal-footer {
  border: none;
  border-color: var(--bg-color);
}

/* Webkit Scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
