.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.multi-select {
  width: 100%;
  margin: 10px 10px 0px 10px;
}
