/* Animations */
.top_right-enter {
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
}

.top_right-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: top right;
  transition: transform 300ms, opacity 300ms;
}

.top_right-exit {
  opacity: 1;
  transform-origin: top right;
}

.top_right-exit-active {
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 300ms, opacity 300ms;
}

.top_left-enter {
  opacity: 0;
  transform: scale(0);
  transform-origin: top left;
}

.top_left-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: top left;
  transition: transform 300ms, opacity 300ms;
}

.top_left-exit {
  opacity: 1;
  transform-origin: top left;
}

.top_left-exit-active {
  opacity: 0;
  transform: scale(0);
  transform-origin: top left;
  transition: transform 300ms, opacity 300ms;
}

.top_center-enter {
  opacity: 0;
  transform: scale(0);
  transform-origin: top center;
}

.top_center-enter-active {
  opacity: 1;
  transform: scale(1);
  transform-origin: top center;
  transition: transform 300ms, opacity 300ms;
}

.top_center-exit {
  opacity: 1;
  transform-origin: top center;
}

.top_center-exit-active {
  opacity: 0;
  transform: scale(0);
  transform-origin: top center;
  transition: transform 300ms, opacity 300ms;
}
