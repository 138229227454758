@font-face {
  font-family: 'Futura-Medium';
  font-style: normal;
  src: url('../assets/fonts/Futura/futura_medium.ttf');
}

@font-face {
  font-family: 'Futura-Light';
  font-style: normal;
  src: url('../assets/fonts/Futura/futura_light.ttf');
}

@font-face {
  font-family: 'Futura-Heavy';
  font-style: normal;
  src: url('../assets/fonts/Futura/futura_heavy.ttf');
}

@font-face {
  font-family: 'Futura-Book';
  font-style: normal;
  src: url('../assets/fonts/Futura/futura_book.ttf');
}
