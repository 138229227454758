/* Animated Underlining */
.purple_underline:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
  background-color: #b044f9;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.purple_underline:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Static Styles */
.static_purple_underline:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Hover Effects */
.purple_hover:hover {
  color: #b044f9 !important;
}

.dark_hover:hover {
  filter: brightness(85%);
}
