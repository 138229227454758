:root[data-theme='theme-light'] {
  --primary-color: #b044f9;
  --secondary-color: #c992f9;
  --text-color: #707070;
  --bg-color: #f6f7fb;
}

:root[data-theme='theme-dark'] {
  --primary-color: #b044f9;
  --secondary-color: #c992f9;
  --text-color: #ffffff;
  --bg-color: #36393e;
}
