.container {
  width: 100%;
  padding: 30px 30px 20px 30px;
  text-align: center;
  background: var(--bg-color);
  border-bottom-left-radius: 25px;
}

.title-wrap {
  width: 100%;
}

.participants-wrap {
  width: 100%;
  text-align: center;
}

.participants-list {
  display: inline-block;
  text-align: left;
  padding: 10px 0px 0px 0px;
  list-style-type: none;
  min-width: 260px;
}

.text {
  color: var(--text-color);
}

.kick-wrapper:hover {
  filter: brightness(70%);
  cursor: pointer;
}
