.container {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.field {
  width: 100%;
  max-width: 300px;
}
